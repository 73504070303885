import React from 'react'
import './quickCall.scss'


const QuickCall = (props) => {

    return (
        <div>
        <div className='quickCall'>
                <div className="main-button">
                    <a href="tel:(513)733-1188">
                        <strong>(513) 733-1188</strong>
                    </a>
                </div>
                <div className="main-button">
                    <a href="/contact">
                        <strong>Contact Us</strong>
                    </a>
                </div>
        </div>
        {/* <div className='quickCall call-bottom-margin'>
                <div className="main-button">
                    <a href="tel:(513)733-1188">
                        <strong>(513) 733-1188</strong>
                    </a>
                </div>
                <div className="main-button">
                    <a href="/contact">
                        <strong>Contact Us</strong>
                    </a>
                </div>
        </div> */}
        </div>
    )
}

export default QuickCall