import React, { useState } from 'react'
import { Link } from 'gatsby'
import './navbar.scss'
import logo from "../../images/ValleyIcon.svg"

import menu from "../../images/menu.svg"
import close from "../../images/close.svg"


const NavBar = (props) => {
    const { title } = props
    const [moblieNav, setMoblieNav] = useState(false);

    function myFunction() {
        if (moblieNav === false) {
            setMoblieNav(true);
            document.getElementById('mobile-nav').classList.add("show-nav");
        } else {
            setMoblieNav(false);
            document.getElementById('mobile-nav').classList.remove("show-nav");
        }
        console.log(moblieNav);
    };

    return (
        <div className='main-nav'>
            <div className='main-nav-inner'>
                <a href="/"><img className='brand-logo' src={logo} alt={title} /></a>
                {/* <a href="/"><img className='brand-logo' src={largeLogo} alt={title} /></a> */}
                <ul className='desktop-links'>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/service-areas/'>Service Areas</Link></li>
                    <li><Link to='/about/'>About</Link></li>
                    <li><Link to='/services/'>Services</Link></li>
                    <li><Link to='/careers/'>Careers</Link></li>
                    <li className='right-nav-item'>
                        <div className="main-button">
                            <a href="tel:(513)733-1188" className="button is-primary">
                                <strong>(513) 733-1188</strong>
                            </a>
                        </div>
                    </li>
                    <li className='right-nav-item'>
                        <div className="button">
                            <a href="/contact/" className="button is-primary">
                                <strong>Contact Us</strong>
                            </a>
                        </div>
                    </li>
                </ul>
                <div className='mobile-nav-menu'>
                    <button type="button" aria-label="Toggle Navigation" onClick={myFunction}>
                        <img className='nav-icon' src={menu} alt="menu" />
                    </button>
                </div>

                <div className="overlay" id="mobile-nav">
                    <div className="buttonRow">
                        <button type="button" aria-label="Close Navigation" onClick={myFunction}>
                            <img className='nav-icon' src={close} alt="close" />
                        </button>
                    </div>

                    <ul className='mobile-links'>
                        <li><Link onClick={myFunction} to='/'>Home</Link></li>
                        <li><Link onClick={myFunction} to='/service-areas/'>Service Areas</Link></li>
                        <li><Link onClick={myFunction} to='/about/'>About</Link></li>
                        <li><Link onClick={myFunction} to='/services/'>Services</Link></li>
                        <li><Link onClick={myFunction} to='/careers/'>Careers</Link></li>
                        <li><Link onClick={myFunction} to='/contact/'>Contact Us</Link></li>
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default NavBar

