import React from 'react'
import Helmet from 'react-helmet'
import config from '../../../config'
import NavBar from '../Navbar'
import Banner from '../Banner'
import SeoOrg from '../SEO/organization'
import SeoLocBus from '../SEO/localBusiness'
import SeoWebsite from '../SEO/website'
import QuickCall from '../quickCall'
import Footer from '../Footer'
import '../style.scss';

const Layout = (props) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: `en`, class: `has-navbar-fixed-top` }}>
        <title>{config.siteTitle}</title>
        <meta charset="UTF-8" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta name="description" content={config.siteDescription} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content={config.themeColor} />
        <meta name="theme-color" content={config.themeColor} />
        <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" sizes="180x180" />
        <link rel="icon" href="/favicon-32x32.png" type="image/png" sizes="32x32" />
        <link rel="icon" href="/favicon-16x16.png" type="image/png" sizes="16x16" />
        <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color={config.themeColor} />
      </Helmet>
      <Banner banner_text={config.banner_text} />
      <QuickCall />
      <NavBar title={config.siteTitle} />
      <SeoOrg />
      <SeoLocBus />
      <SeoWebsite url={config.siteUrl} title={config.siteTitle} />
      <div className="body-content">
        <>{props.children}</>        
      </div>
      <Footer copyright={config.copyright} />
      {/* <script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/20178353.js"></script> */}
      <script type="text/javascript" id="google-optimize" async defer src={"//www.googleoptimize.com/optimize.js?id=" + config.googleOptimize} />
    </>
  )
}

export default Layout