import React from 'react'
import './banner.scss'


const Banner = (props) => {
  const { banner_text } = props

  return (
      <div className='banner-container'>
          <p>
            {banner_text}
          </p>
      </div>
  )
}

export default Banner